* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
  text-transform: uppercase;
}

body {
  color: white!important;
  background: #000;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

#links{
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: auto;
  z-index: 10;
  padding: 0 2.5% 2.5% 2.5%;
}

#links a{
  font-family: sans-serif;
  text-decoration: none;
  display: block;
  opacity: 1;
  text-transform: uppercase;
}

a:hover{
  cursor: pointer;
}

a#full-experience{
  color: white;
  display: block;
  margin: 0 auto 0 0;
  text-decoration: underline;
}

#links a#dwnload-video{
  display: block;
  margin: 0 0 0 auto;
}


#instructions{
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 2.5%;
  left: 0;
  width: 95vw;
  height: auto;
  z-index: 10;
  padding: 0 2.5% 2.5% 2.5%;
  opacity: 0;
  will-change: opacity;
}

#instructions span{
  display: block;
  width: 100%;
  float: left;
}

#play-audio-btn:hover{
  cursor: pointer;
}

#play-audio-btn.selected{
  background: white;
  color: black;
  border: 1px solid black;
  transition: 300ms;
}

#presets{
  float: left;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 12px;
  opacity: 0;
  will-change: opacity;
}
.ui-btn{
  display: block;
  border: 1px solid white;
  padding: 6px 12px;
  float: left;
  margin: 0 0 0 12px;
  width: auto;
  border-radius: 6px;
  align-self: center;
  transition: 300ms;
}

.ui-btn:hover{
  cursor: pointer;
  background: white;
  color: black;
  border: 1px solid black;
  transition: 300ms;
}

.ui-btn:hover svg{
  fill: black;
  transition: 300ms;
}

.ui-btn.selected{
  background: white;
  color: black;
  border: 1px solid black;
  transition: 300ms;
}

.ui-btn.selected svg{
  fill: black;
  transition: 300ms;
}


#resolutions{
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 2%;
  opacity: 0;
  will-change: opacity;
}
.noise_resolution{
  display: flex;
}
.noise_resolution svg{
  content:'';
  background: url('../static/warning-sign-svgrepo-com.svg'); /*url of image*/
  height: 12px; /*height of image*/
  width: 24px;  /*width of image*/
  align-self: center;
  display: block;
  float: left;
  color: white;
  fill: white;
}

#buttons__area{
  width: 100vw;
  height: auto;
  display: block;
  position: fixed;
  bottom: 2.5%;
  right: 2.5%;
  float: left;
  z-index: 10;
}

#start-exp{
  display: flex;
  position: fixed;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  will-change: opacity;
}

#start-exp #start-btn{
  margin: 0 auto;
}



/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  body {
    font-size: 12px;
  }

  .ui-btn{
    padding: 6px 12px;
    margin: 0 0 0 6px;
    border-radius: 6px;
  }
  #play-audio-btn{
    float: right;
    margin-left: 70%;
    margin-bottom: 12px;
  }
}

canvas{
  opacity: 0;
}